import styles from "./index.module.scss";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { getType, getTypeUrl } from "../../../utils/index";
import { fetchSections } from "/redux/action-creaters/sections";
import Loader from "/components/common/loader";
import { Api } from "/api";
import Button from "/components/common/button";

export default function Selector({ isBaltex = false }) {
  const dispatch = useDispatch();

  const { topMarks } = useSelector((state) => state.models);
  const [selectedMark, setSelectedMark] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [marks, setMarks] = useState({});
  const [models, setModels] = useState({});
  const [types, setTypes] = useState({});
  const { sections } = useSelector((state) => state.sections);
  const [isLoading, setLoading] = useState(false);
  const [showAllMarks, setShowAllMarks] = useState(false);

  const topMarksFiltered = useMemo(() => {
    return topMarks.filter((mark) => marks[mark.CODE]);
  }, [topMarks, marks]);

  const marksBySymbol = useMemo(() => {
    const result = {};
    topMarks.forEach((mark) => {
      if (marks[mark.CODE]) {
        if (!result[mark.NAME[0]]) {
          result[mark.NAME[0]] = [];
        }
        result[mark.NAME[0]].push(mark);
      }
    });
    return result;
  }, [topMarks, marks]);

  const steps = {
    mark: "mark",
    model: "model",
    type: "type",
    section: "section",
  };

  const currentStep = useMemo(() => {
    if (!selectedMark) {
      return steps.mark;
    }
    if (!selectedModel) {
      return steps.model;
    }
    if (!selectedType) {
      return steps.type;
    }
    return steps.section;
  }, [selectedMark, selectedModel, selectedType]);

  const currentMark = useMemo(() => {
    return selectedMark
      ? topMarks.find((item) => +item.ID === +selectedMark)
      : null;
  }, [selectedMark, topMarks]);

  const currentModel = useMemo(() => {
    return models[selectedModel];
  }, [selectedModel, models]);

  const currentType = useMemo(() => {
    return types[selectedType];
  }, [selectedType, types]);

  const getUrl = (id, isCommon = false) => {
    const sectionUrl = sections[id].SECTION_PAGE_URL;
    return isCommon
      ? sectionUrl
      : getTypeUrl(sectionUrl, selectedMark, selectedModel, selectedType);
  };

  const resetAll = () => {
    setSelectedMark("");
    setSelectedModel("");
    setSelectedType("");
  };

  useEffect(() => {
    if (typeof localStorage === "object") {
      const mark = localStorage.getItem("mark_");
      // eslint-disable-next-line eqeqeq
      if (mark && mark != "0") {
        const model = localStorage.getItem("model_");
        // eslint-disable-next-line eqeqeq
        if (model && model != "0") {
          setSelectedModel(model);
          const type = localStorage.getItem("type_");
          // eslint-disable-next-line eqeqeq
          if (type && type != "0") {
            // eslint-disable-next-line no-use-before-define
            handlesSelectedType(mark, model, type);
          } else {
            // eslint-disable-next-line no-use-before-define
            handleSelectedModel(mark, model);
          }
        } else {
          // eslint-disable-next-line no-use-before-define
          handleSelectedMark(mark);
        }
      } else {
        Api.server.getMarks(isBaltex).then((data) => {
          setMarks(data.marks);
        });
      }
    }
  }, []);

  useEffect(async () => {
    if (selectedType) {
      if (sections.length === 0) {
        await dispatch(await fetchSections());
      }
    }
  }, [selectedType, sections]);

  const isMobile = useMemo(() => {
    if (typeof window === "undefined") {
      return false;
    }
    return window.innerWidth < 991;
  });

  const handleSelectedMark = async (selectedMark) => {
    if (!selectedMark) {
      return;
    }
    const selector = document.getElementById("mark-selector");
    if (window.scrollY > selector.offsetTop) {
      window.scrollTo({ top: selector.offsetTop, behavior: "smooth" });
    }
    if (typeof localStorage === "object") {
      localStorage.setItem("mark_", selectedMark);
      localStorage.setItem("model_", "");
      localStorage.setItem("type_", "");
    }
    setLoading(true);
    const data = await Api.server.getMarks(isBaltex, selectedMark);
    setMarks(data.marks);
    setModels(data.models);
    setTypes({});

    setSelectedMark(selectedMark);
    setSelectedModel("");
    setSelectedType("");
    setLoading(false);

    if (Object.keys(data.models).length === 1) {
      setTimeout(() => {
        // eslint-disable-next-line no-use-before-define
        handleSelectedModel(selectedMark, Object.keys(data.models)[0]);
      }, 0);
    }
  };

  const handleSelectedModel = async (selectedMark, selectedModel) => {
    if (!selectedMark || !selectedModel) {
      return;
    }
    if (typeof localStorage === "object") {
      localStorage.setItem("mark_", selectedMark);
      localStorage.setItem("model_", selectedModel);
      localStorage.setItem("type_", "");
    }
    setLoading(true);
    const data = await Api.server.getMarks(
      isBaltex,
      selectedMark,
      selectedModel,
    );
    setMarks(data.marks);
    setModels(data.models);
    setTypes(data.types);
    setSelectedMark(selectedMark);
    setSelectedModel(selectedModel);
    setSelectedType("");
    setLoading(false);

    if (Object.keys(data.types).length === 1) {
      setTimeout(() => {
        // eslint-disable-next-line no-use-before-define
        handlesSelectedType(
          selectedMark,
          selectedModel,
          Object.keys(data.types)[0],
        );
      }, 0);
    }
  };

  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedCommonSections, setSelectedCommonSections] = useState([]);
  const handlesSelectedType = async (
    selectedMark,
    selectedModel,
    selectedType,
  ) => {
    if (!selectedMark || !selectedModel || !selectedType) {
      return;
    }
    if (typeof localStorage === "object") {
      localStorage.setItem("mark_", selectedMark);
      localStorage.setItem("model_", selectedModel);
      localStorage.setItem("type_", selectedType);
    }
    setLoading(true);
    const data = await Api.server.getMarks(
      isBaltex,
      selectedMark,
      selectedModel,
      selectedType,
    );
    setMarks(data.marks);
    setModels(data.models);
    setTypes(data.types);
    setSelectedSections(data.sections);
    setSelectedCommonSections(data.commonSections);

    if (typeof localStorage === "object") {
      localStorage.setItem(
        "filterUrl",
        `/filter/marka-${selectedMark}/model-${selectedModel}/kuzov-${selectedType}`,
      );
    }
    setSelectedMark(selectedMark);
    setSelectedModel(selectedModel);
    setSelectedType(selectedType);
    setLoading(false);
  };

  const sectionsData = useMemo(() => {
    return (
      (sections &&
        Object.keys(sections).length > 0 &&
        Object.keys(selectedSections)
          .map((sectionId) => {
            return {
              NAME: sections[sectionId].NAME,
              COUNT: selectedSections[sectionId],
              ID: sectionId,
              SORT:
                sections[sectionId].UF_SORT_FILTER ?? sections[sectionId].SORT,
            };
          })
          .sort((a, b) => parseInt(a.SORT, 10) - parseInt(b.SORT, 10))) ||
      []
    );
  }, [selectedSections, sections]);

  const commonSectionsData = useMemo(() => {
    return (
      (sections &&
        Object.keys(sections).length > 0 &&
        Object.keys(selectedCommonSections)
          .map((sectionId) => {
            return {
              NAME: sections[sectionId].NAME,
              COUNT: selectedCommonSections[sectionId],
              ID: sectionId,
              SORT:
                sections[sectionId].UF_SORT_FILTER ?? sections[sectionId].SORT,
            };
          })
          .sort((a, b) => parseInt(a.SORT, 10) - parseInt(b.SORT, 10))) ||
      []
    );
  }, [selectedCommonSections, sections]);

  return (
    <div className={styles.Selector} id="mark-selector">
      <div className={styles.Top} data-type={currentStep}>
        <h2>Выберите ваш автомобиль</h2>
        <select
          className={styles.Select}
          value={selectedMark}
          onChange={(event) => handleSelectedMark(event.target.value)}
          data-value={selectedMark}
        >
          <option value="" disabled>
            Выберите марку
          </option>
          {topMarksFiltered.map((mark) => (
            <option key={mark.CODE} value={mark.CODE}>
              {mark.NAME}
            </option>
          ))}
        </select>
        {selectedMark && (
          <select
            className={styles.Select}
            value={selectedModel}
            onChange={(event) =>
              handleSelectedModel(selectedMark, event.target.value)
            }
            data-value={selectedModel}
          >
            <option value="" disabled>
              Выберите модель
            </option>
            {Object.keys(models)
              .sort()
              .map((modelCode) => (
                <option key={modelCode} value={modelCode}>
                  {models[modelCode]}
                </option>
              ))}
          </select>
        )}
        {selectedModel && (
          <select
            className={styles.Select}
            value={selectedType}
            onChange={(event) =>
              handlesSelectedType(
                selectedMark,
                selectedModel,
                event.target.value,
              )
            }
            data-value={selectedType}
          >
            <option value="" disabled>
              Выберите кузов
            </option>
            {Object.keys(types)
              .sort()
              .map((typeCode) => (
                <option key={typeCode} value={typeCode}>
                  {types[typeCode]}
                </option>
              ))}
          </select>
        )}
        <div className={styles.Reset} onClick={resetAll}>
          Сбросить все
        </div>
      </div>
      {isLoading && (
        <div className={styles.Loader}>
          <Loader />
        </div>
      )}
      {showAllMarks && currentStep === steps.mark && !isMobile && (
        <div className={styles.Symbols}>
          {Object.keys(marksBySymbol).map((symbol) => (
            <div className={styles.Symbol} key={symbol}>
              <span>{symbol}</span>
              <ul className={styles.Marks}>
                {marksBySymbol[symbol].map((mark) => (
                  <li
                    key={mark.ID}
                    onClick={() => handleSelectedMark(mark.CODE)}
                  >
                    <img src={mark.PICTURE_SRC} />
                    <span>{mark.NAME}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
      {!showAllMarks && currentStep === steps.mark && !isMobile && (
        <div>
          <ul className={styles.TopMarks}>
            {topMarks
              .filter((mark) => mark.TOP)
              .map((mark) => (
                <li key={mark.ID} onClick={() => handleSelectedMark(mark.CODE)}>
                  <span>
                    <img src={mark.PICTURE_SRC} />
                  </span>
                </li>
              ))}
          </ul>
          <ul className={clsx(styles.Items, styles.ItemsMarks)}>
            {topMarks
              .filter((mark) => mark.RECOMENDED)
              .map((mark) => (
                <li key={mark.ID} onClick={() => handleSelectedMark(mark.CODE)}>
                  <span>{mark.NAME}</span>
                </li>
              ))}
          </ul>
          <Button
            label={"Показать все марки"}
            type={"outlineGray"}
            onClick={() => {
              setShowAllMarks(true);
            }}
          />
        </div>
      )}
      {currentStep === steps.model && !isMobile && (
        <ul className={styles.Items}>
          {Object.keys(models)
            .sort()
            .map((modelCode) => (
              <li
                key={modelCode}
                onClick={() => handleSelectedModel(selectedMark, modelCode)}
              >
                <span>{models[modelCode]}</span>
              </li>
            ))}
        </ul>
      )}
      {currentStep === steps.type && !isMobile && (
        <ul className={clsx(styles.Items, styles.TypesItems)}>
          {Object.keys(types)
            .sort()
            .map((typeCode) => getType(types[typeCode], typeCode))
            .sort((a, b) => a.sort - b.sort)
            .map((type) => (
              <li
                key={type.code}
                onClick={() =>
                  handlesSelectedType(selectedMark, selectedModel, type.code)
                }
              >
                <span>{type.name}</span>
                {type.gen && <div className={styles.Gen}>{type.gen}</div>}
              </li>
            ))}
        </ul>
      )}
      {currentStep === steps.section && (
        <>
          <div className={styles.SectionsTitle}>
            Товары для {currentMark?.NAME} {currentModel?.NAME}{" "}
            {currentType?.NAME}
          </div>
          <ul className={styles.SectionsItems}>
            {sectionsData.map((item) => (
              <a key={item.ID} href={getUrl(item.ID)}>
                <span>{item.NAME}</span>
                <div className={styles.Count}>{item.COUNT}</div>
              </a>
            ))}
          </ul>
          {commonSectionsData.length > 0 && (
            <>
              <div className={styles.SectionsTitle}>Универсальные товары</div>
              <div className={styles.SectionsItems}>
                {commonSectionsData.map((item) => (
                  <a key={item.ID} href={getUrl(item.ID, true)}>
                    <span>{item.NAME}</span>
                    <div className={styles.Count}>{item.COUNT}</div>
                  </a>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
